.create-tournament input[type="text" i],
.create-tournament textarea,
.create-tournament div.react-datetime-picker {
    margin-bottom: 20px;
    background-color: white;
    color: black;
}

.create-tournament input[type="text" i],
.create-tournament textarea,
.create-tournament .react-datetime-picker__wrapper
{
    padding: 10px;
    width: 90%;
}

.create-tournament button[type="submit"] {
    border: 1px solid green;
    background-color: green;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    color: white;
    width: 100%;
}